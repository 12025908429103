import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { CircularProgress, Modal } from '@material-ui/core';
import { hideLoading } from 'actions';

const styles = () => ({
	paper: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		outline: 'none'
	}
});

const Loading = ({ classes, loading, handleClose }) => (
	<Modal open={loading} onClose={handleClose}>
		<div className={classes.paper}>
			<CircularProgress
				color="secondary"
				size={48}
				disableShrink
				variant="indeterminate"
			/>
		</div>
	</Modal>
);

const mapStateToProps = state => ({ loading: state.loading.loading });

const mapDispatchToProps = dispatch => ({
	handleClose: () => dispatch(hideLoading())
});

Loading.propTypes = {
	classes: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired
};

// We need an intermediary variable for handling the recursive nesting.
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Loading));
