import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Page404 } from 'pages';
import { Loading, Toast } from 'components';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
	'@global': {
		'html, body, #app': {
			height: '100%'
		}
	}
});

const hello = () => (
	<div>
		<h1>HOLA SOY WOLA EXCHANGE</h1>
	</div>
);
const Routers = () => (
	<>
		<Loading />
		<Toast />
		<Switch>
			<Route exact path="/" component={hello} />
			{/* <Route exact path="/home" component={Home} />
			<Route exact path="/recovery" component={Recovery} />
			<Route path="/reset" component={ResetPassword} /> */}
			<Route component={Page404} />
		</Switch>
	</>
);

export default withStyles(styles)(Routers);
