import axios from 'axios';
import { url_auth } from 'config';

const login = async (email, password) => {
	const args = {
		email,
		password
	};
	try {
		const { data } = await axios.post(`${url_auth}/login`, args);
		return data;
	} catch (error) {
		console.log(`Error in login ${error}`);
		return false;
	}
};

const recovery = async email => {
	const args = {
		email,
		name_browser: navigator.userAgent,
		name_system: navigator.platform
	};
	try {
		const { data } = await axios.post(`${url_auth}/recovery`, args);
		return data;
	} catch (error) {
		console.log(`Error in logout ${error}`);
		return false;
	}
};
const reset = async (token, password) => {
	const args = {
		token,
		password
	};
	try {
		const { data } = await axios.post(`${url_auth}/reset`, args);
		console.log(data);
		return data;
	} catch (error) {
		console.log(`Error in logout ${error}`);
		return false;
	}
};

// const logout = async () => {
// 	try {
// 		const response = await axios.get(
// 			'https://jsonplaceholder.typicode.com/posts/'
// 		);
// 		return response;
// 	} catch (error) {
// 		console.log(`Error in logout ${error}`);
// 		return false;
// 	}
// };

export default { login, recovery, reset };
