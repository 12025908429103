import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	CardActions,
	CardContent,
	Button,
	TextField,
	CardHeader,
	Avatar,
	InputAdornment,
	Box
} from '@material-ui/core';
import { Send, Mail, Lock } from '@material-ui/icons';

import { wolaMediumSquare } from 'img';
import { User } from 'rest';
import { showLoading, hideLoading, showToast, saveToken } from 'actions';

const useStyles = makeStyles(theme => ({
	card: {
		minWidth: 275
	},
	extendedIcon: {
		marginRight: theme.spacing(0.5)
	},
	action: {
		flexDirection: 'row-reverse'
	},
	avatar: {
		width: 85,
		height: 80,
		borderRadius: 0
	},
	darkColor: {
		color: theme.palette.primary.dark // or theme.palette.primary.main
	}
}));

const LoginForm = ({ history }) => {
	const [values, setValues] = useState({});
	const [validate, setValidate] = useState({});
	const dispatch = useDispatch();
	const handleShowLoading = () => dispatch(showLoading());
	const handleHideLoading = () => dispatch(hideLoading());
	const handleShowToast = msg => dispatch(showToast(msg));
	const handleSaveToken = token => dispatch(saveToken(token));
	const classes = useStyles();

	const handleRecovery = () => {
		history.push('recovery');
	};

	const validateEmail = value => {
		if (!value || value.length < 1) return false;
		return /^\S+@\S+\.\S{2,4}/i.test(value);
	};

	const handleChangeEmail = ({ target: { value } }) => {
		setValues({
			...values,
			email: value
		});
		setValidate({
			...validate,
			email: validateEmail(value)
		});
	};

	const validatePassword = value => value && value.length >= 4;

	const handleChangePassword = ({ target: { value } }) => {
		setValues({
			...values,
			password: value
		});
		setValidate({
			...validate,
			password: validatePassword(value)
		});
	};

	const handleClick = async () => {
		const { email, password } = values;
		handleShowLoading();
		const ok = await User.login(email, password);
		handleHideLoading();
		if (!ok) {
			handleShowToast('Error de autenticación, valide las credenciales.');
			return;
		}
		handleSaveToken(ok.token);
		// console.log(ok);
		history.push('/home');
	};

	const handleKeyPress = e => {
		// console.log(this.handleValidation())
		const { email, password } = validate;
		if (/enter/gi.test(e.key) && password && email) {
			handleClick();
		}
	};

	return (
		<Fragment>
			<Card className={classes.card}>
				<Box display="flex" justifyContent="center" m={1} p={1}>
					<CardHeader
						className={classes.cardHeader}
						avatar={
							<Avatar src={wolaMediumSquare} className={classes.avatar} />
						}
					/>
				</Box>
				<CardContent>
					<TextField
						required
						id="email"
						label="Email"
						helperText="example@example.com"
						value={values.email || ''}
						onChange={handleChangeEmail}
						onKeyDown={handleKeyPress}
						error={values.email && !validate.email}
						margin="normal"
						autoComplete="off"
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Mail />
								</InputAdornment>
							)
						}}
					/>
					<TextField
						required
						id="password"
						label="Password"
						helperText=""
						value={values.password || ''}
						type="password"
						onChange={handleChangePassword}
						onKeyDown={handleKeyPress}
						margin="normal"
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Lock />
								</InputAdornment>
							)
						}}
					/>
					<Button
						size="medium"
						className={classes.darkColor}
						onClick={() => handleRecovery()}
					>
						¿Olvido la contrase&Ntilde;a?
					</Button>
				</CardContent>
				<CardActions className={classes.action}>
					<Button
						id="btn_login"
						onClick={e => handleClick(e)}
						disabled={!validate.email || !validate.password}
						color="secondary"
						className={classes.button}
						variant="contained"
					>
						<Send className={classes.extendedIcon} />
						Login
					</Button>
				</CardActions>
			</Card>
		</Fragment>
	);
};

LoginForm.propTypes = {
	history: PropTypes.object.isRequired
};
export default withRouter(LoginForm);
