import { LOADING } from 'types';

export const hideLoading = () => ({
	type: LOADING.HIDE_LOADING,
	payload: {
		loading: false
	}
});

export const showLoading = () => ({
	type: LOADING.SHOW_LOADING,
	payload: {
		loading: true
	}
});
