import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import red from '@material-ui/core/colors/red';
import { Provider } from 'react-redux';
import store from 'store';
import Routers from './routers';

let theme = createMuiTheme({
	palette: {
		primary: {
			light: '#fff64f',
			main: '#ffc400',
			dark: '#c79400',
			contrastText: '#000000'
		},
		secondary: {
			light: '#4f5b62',
			main: '#263238',
			dark: '#000a12',
			contrastText: '#ffffff'
		},
		error: red
	},
	spacing: 5,
	typography: {
		useNextVariants: true,
		fontFamily: '"Comfortaa", cursive;'
	}
});

theme = responsiveFontSizes(theme);

// const App = () => <h1>Hola mundo</h1>;

const App = () => (
	<ThemeProvider theme={theme}>
		<CssBaseline />
		<Provider store={store}>
			<BrowserRouter>
				<Routers />
			</BrowserRouter>
		</Provider>
	</ThemeProvider>
);

export default App;
