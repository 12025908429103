import cloneDeep from 'lodash/cloneDeep';
import { USER } from 'types';

const inicialState = {
	email: null,
	username: null,
	token: null,
	type: 'guest'
};

const user = (prevstate = cloneDeep(inicialState), action) => {
	const { type, payload } = action;
	switch (type) {
		case USER.GET:
			return {
				...prevstate,
				...payload
			};

		case USER.POST:
			return {
				...prevstate
			};

		case USER.PUT_USERNAME:
		case USER.PUT_PASSWORD:
			return {
				...prevstate
			};
		case USER.SAVE_TOKEN:
			return {
				...prevstate,
				...payload
			};

		default:
			return prevstate;
	}
};

export default user;
