import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from 'reducers';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension';
import { logger } from 'middlewares';

export default createStore(
	reducer,
	composeWithDevTools(applyMiddleware(thunk, logger))
);
