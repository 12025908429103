const GET = 'GET';
const POST = 'POST';
const PUT_USERNAME = 'PUT_USERNAME';
const PUT_PASSWORD = 'PUT_PASSWORD';
const SAVE_TOKEN = 'SAVE_TOKEN';
const LOGOUT = 'LOGOUT';

export default {
	GET,
	POST,
	PUT_USERNAME,
	PUT_PASSWORD,
	SAVE_TOKEN,
	LOGOUT
};
