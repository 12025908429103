import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/styles';
import { hideToast } from 'actions';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
	close: {
		padding: theme.spacing(0.5)
	}
}));

const SlideTransition = props => {
	return <Slide {...props} direction="up" />;
};

const Toast = () => {
	const { toggle, msg } = useSelector(state => state.toast);
	const dispatch = useDispatch();
	const hidetoast = () => dispatch(hideToast());
	const classes = useStyles();

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		if (toggle) hidetoast();
	};

	return (
		<div>
			<Snackbar
				TransitionComponent={SlideTransition}
				key={new Date().getTime()}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				open={toggle}
				autoHideDuration={6000}
				onClose={handleClose}
				onExited={() => hidetoast()}
				ContentProps={{
					'aria-describedby': 'message-id'
				}}
				message={msg}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						className={classes.close}
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
				]}
			/>
		</div>
	);
};

export default Toast;
