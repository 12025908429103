import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	CardActions,
	CardContent,
	Button,
	TextField,
	CardHeader,
	Avatar,
	InputAdornment,
	Box,
	Grid,
	Typography
} from '@material-ui/core';
import { Send, Lock, CheckCircle } from '@material-ui/icons';

import { wolaMediumSquare } from 'img';
import { User } from 'rest';
import { showLoading, hideLoading, showToast, saveToken } from 'actions';

const useStyles = makeStyles(theme => ({
	card: {
		minWidth: 275
	},
	extendedIcon: {
		marginRight: theme.spacing(0.5)
	},
	action: {
		flexDirection: 'row-reverse'
	},
	avatar: {
		width: 85,
		height: 80,
		borderRadius: 0
	},
	darkColor: {
		color: theme.palette.primary.dark // or theme.palette.primary.main
	}
}));

const ResetForm = ({ history, token }) => {
	const [values, setValues] = useState({});
	const [validate, setValidate] = useState(false);
	const count = 5;
	const dispatch = useDispatch();
	const handleShowLoading = () => dispatch(showLoading());
	const handleHideLoading = () => dispatch(hideLoading());
	const handleShowToast = msg => dispatch(showToast(msg));
	const handleSaveToken = _token => dispatch(saveToken(_token));
	const classes = useStyles();

	const validatePassword = value => {
		return value.length >= count;
	};

	const isValidate = () => {
		const { password, confirm } = values;
		return (
			password === confirm &&
			validate &&
			/(?=.*[a-z]+)(?=.*[A-Z]+)(?=.*\d+)(?=.*[!@#$%^&*(),.?":{}|<>]+)/gm.test(
				password
			)
		);
	};
	const handleChangePassword = ({ target: { value } }) => {
		setValues(prevValues => ({
			...prevValues,
			password: value
		}));
		setValidate(validatePassword(value));
	};

	const handleChangeConfirm = ({ target: { value } }) => {
		setValues(prevValues => ({
			...prevValues,
			confirm: value
		}));
		setValidate(validatePassword(value));
	};

	const handleClick = async () => {
		const { password } = values;
		handleShowLoading();
		const ok = await User.reset(token, password);
		console.log(ok);
		handleHideLoading();
		if (!ok) {
			handleShowToast(
				'Error al guardar la nueva contraseña, repita el proceso de recuperacion'
			);
			setValues({});
			return;
		}
		handleSaveToken(ok.token);
		// console.log(ok);
		history.push('/home');
	};

	const handleKeyPress = e => {
		// console.log(this.handleValidation());
		if (/enter/gi.test(e.key) && validate) {
			handleClick();
		}
	};

	return (
		<Fragment>
			<Card className={classes.card}>
				<Box display="flex" justifyContent="center" m={1} p={1}>
					<CardHeader
						className={classes.cardHeader}
						avatar={
							<Avatar src={wolaMediumSquare} className={classes.avatar} />
						}
					/>
				</Box>
				<CardContent>
					<Grid
						container
						direction="column"
						justify="center"
						alignItems="center"
					>
						<Typography variant="h5" component="h3">
							Cambio de contraseña
						</Typography>
						<Typography variant="subtitle2" gutterBottom />
					</Grid>
					<TextField
						required
						id="password"
						label="Password"
						helperText=""
						value={values.password || ''}
						type="password"
						onChange={handleChangePassword}
						onKeyDown={handleKeyPress}
						error={values.password !== values.confirm}
						margin="normal"
						variant="outlined"
						fullWidth
						autoFocus
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Lock />
								</InputAdornment>
							)
						}}
					/>
					<TextField
						required
						id="comfirm"
						label="Confirm"
						helperText={`Mínimo ${count} caracteres con minino 1 mayúscula, 1 minúscula, 1 número y 1 caracter especial `}
						value={values.confirm || ''}
						type="password"
						onChange={handleChangeConfirm}
						onKeyDown={handleKeyPress}
						error={!isValidate}
						margin="normal"
						variant="outlined"
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<CheckCircle />
								</InputAdornment>
							)
						}}
					/>
				</CardContent>
				<CardActions className={classes.action}>
					<Button
						id="btn_reset"
						onClick={e => handleClick(e)}
						disabled={!isValidate()}
						color="secondary"
						className={classes.button}
						variant="contained"
					>
						<Send className={classes.extendedIcon} />
						Reset
					</Button>
				</CardActions>
			</Card>
		</Fragment>
	);
};

ResetForm.propTypes = {
	history: PropTypes.object.isRequired,
	token: PropTypes.string.isRequired
};
export default withRouter(ResetForm);
