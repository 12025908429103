export const logger = store => next => action => {
	console.log('dispatching', action);
	const result = next(action);
	console.log('next state', store.getState());
	return result;
};

export const middleware = store => next => action => {
	console.log('dispatching', action);
	const result = next(action);
	console.log('next state', store.getState());
	return result;
};

export default {
	logger,
	middleware
};
