import cloneDeep from 'lodash/cloneDeep';
import { LOADING } from 'types';

const initialState = {
	loading: false
};

const loadingEffect = (prevstate = cloneDeep(initialState), action) => {
	const { type, payload } = action;
	switch (type) {
		case LOADING.HIDE_LOADING:
		case LOADING.SHOW_LOADING: {
			const { loading } = payload;
			return {
				...prevstate,
				loading
			};
		}

		default:
			return prevstate;
	}
};

export default loadingEffect;
