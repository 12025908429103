import { TOAST } from 'types';

export const hideToast = () => ({
	type: TOAST.HIDE_TOAST,
	payload: {
		toggle: false,
		msg: null
	}
});

export const showToast = msg => ({
	type: TOAST.SHOW_TOAST,
	payload: {
		toggle: true,
		msg
	}
});
