import { USER } from 'types';

export const getUser = args => ({
	type: USER.GET,
	payload: {
		args
	}
});

export const postUser = args => ({
	type: USER.POST,
	payload: {
		args
	}
});

export const putUsername = args => ({
	type: USER.PUT_USERNAME,
	payload: {
		args
	}
});

export const putPassword = args => ({
	type: USER.PUT_USERNAME,
	payload: {
		args
	}
});

export const saveToken = token => ({
	type: USER.SAVE_TOKEN,
	payload: {
		token
	}
});
