import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1
	},
	footer: {
		alignItems: 'center',
		padding: theme.spacing(3),
		background_color: theme.palette.background.secondary
	}
}));

const Footer = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<footer position="relative" className={classes.footer}>
				<Typography variant="subtitle2" className={classes.text} align="center">
					<a
						href="https://www.planbacademia.com/"
						target="_blank"
						rel="noopener noreferrer"
					>
						Plan &beta; Academia
					</a>
					&trade; {new Date().getFullYear()}.
				</Typography>
			</footer>
		</div>
	);
};

export default Footer;
