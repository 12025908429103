import { combineReducers } from 'redux';

import user from './User';
import loading from './Loading';
import toast from './Toast';

export default combineReducers({
	user,
	loading,
	toast
});
