import cloneDeep from 'lodash/cloneDeep';
import { TOAST } from 'types';

const initialState = {
	toggle: false,
	msg: null
};

const loadingEffect = (prevstate = cloneDeep(initialState), action) => {
	const { type, payload } = action;
	switch (type) {
		case TOAST.HIDE_TOAST:
		case TOAST.SHOW_TOAST: {
			return {
				...prevstate,
				...payload
			};
		}

		default:
			return prevstate;
	}
};

export default loadingEffect;
