import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Footer } from 'components';
import ResetForm from './ResetForm';

const ResetPassword = ({ location }) => {
	const params = new URLSearchParams(location.search);
	return (
		<Grid
			container
			spacing={0}
			alignItems="center"
			justify="center"
			style={{ minHeight: '100vh' }}
		>
			<Grid item xs={10} sm={4}>
				<ResetForm token={params.get('token')} />
				<Footer />
			</Grid>
		</Grid>
	);
};

ResetPassword.propTypes = {
	location: PropTypes.object.isRequired
};
export default ResetPassword;
