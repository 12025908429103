import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	CardActions,
	CardContent,
	Button,
	TextField,
	CardHeader,
	Avatar,
	Typography,
	Box,
	Grid
} from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { wolaMediumSquare } from 'img';
import { User } from 'rest';
import { showLoading, hideLoading, showToast } from 'actions';

const useStyles = makeStyles(theme => ({
	card: {
		minWidth: 275
	},
	cardHeader: {
		textAlign: 'center'
	},
	extendedIcon: {
		marginRight: theme.spacing(0.5)
	},
	avatar: {
		width: 85,
		height: 80,
		borderRadius: 0
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	action: {
		flexDirection: 'row-reverse'
	}
}));
const Recovery = () => {
	const [email, setEmail] = useState('');
	const [validate, setValidate] = useState(false);
	const classes = useStyles();
	const dispatch = useDispatch();
	const handleShowLoading = () => dispatch(showLoading());
	const handleHideLoading = () => dispatch(hideLoading());
	const handleShowToast = msg => dispatch(showToast(msg));

	const validateEmail = value => {
		if (!value || value.length < 1) return false;
		return /^\S+@\S+\.\S{2,4}/i.test(value);
	};

	const handleChangeEmail = ({ target: { value } }) => {
		setEmail(value);
		setValidate(validateEmail(value));
	};

	const handleRecovery = async () => {
		console.log(email);
		handleShowLoading();
		const ok = await User.recovery(email);
		handleHideLoading();
		if (!ok) {
			handleShowToast('Error al enviar el correo, intentelo de nuevo');
			return;
		}
		handleShowToast('Verifica tu correo para recuperar tu contraseña');
	};

	const handleKeyPress = e => {
		if (/enter/gi.test(e.key) && validate) {
			handleRecovery();
		}
	};
	return (
		<Card className={classes.card}>
			<Box display="flex" justifyContent="center" m={1} p={1}>
				<CardHeader
					className={classes.cardHeader}
					avatar={<Avatar src={wolaMediumSquare} className={classes.avatar} />}
				/>
			</Box>
			<CardContent>
				<Grid container direction="column" justify="center" alignItems="center">
					<Typography variant="h5" component="h3">
						Encuentra tu email
					</Typography>
					<Typography component="p">
						Ingresa tu email registrado para recuperar la cuenta.
					</Typography>
				</Grid>
				<TextField
					id="outlined-email-input"
					label="Email"
					className={classes.textField}
					type="email"
					name="email"
					autoComplete="email"
					margin="normal"
					variant="outlined"
					value={email || ''}
					fullWidth
					onChange={handleChangeEmail}
					onKeyDown={handleKeyPress}
				/>
			</CardContent>

			<CardActions className={classes.action}>
				<Button
					variant="contained"
					color="secondary"
					className={classes.button}
					onClick={handleRecovery}
					disabled={!validate}
				>
					<Send className={classes.extendedIcon} />
					Send
				</Button>
			</CardActions>
		</Card>
	);
};

export default Recovery;
